import React from 'react'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Breadcrumb from '../../components/breadcrumb'
import IndexPage from '../../components/indexPage'

const UsabilityPage = ({location}) => {
  return (
    <Layout year="2021" simpleHeader={true} url="/2021/utilisabilite" type="index">
      <SEO 
        title="Les clefs pour optimiser l’utilisabilité d’un site - 2021"
        description="Utilisabilité : quelles sont les marques qui rendent l’expérience de navigation sur leur site la plus simple, pratique et cohérente ?"
        path="/2021/utilisabilite"
        year="2021" />

      <Breadcrumb url="/2021/utilisabilite" label="Utilisabilité" year="2021" />

      <h1 className="sr-only">Utilisabilité</h1>
      <IndexPage slug="usability" type="index" year="2021" />
    </Layout>
  )
  }

export default UsabilityPage

